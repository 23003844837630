import React, { useEffect, useState } from "react";
import {
  collection,
  doc,
  DocumentSnapshot,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { auth, db } from "../firebase";
import { onAuthStateChanged, updateProfile, User } from "firebase/auth";
import { useNavigate } from "react-router-dom";

export default function UserInfo() {
  let navigate = useNavigate();
  let [user, setUser] = useState<User>();
  let [inputUI, setInputUI] = useState<{ [x: string]: string }>({
    name: "",
    fname: "",
    village: "",
  });
  let [jsx, SetJsx] = useState(
    <div>Initial JSX, please report if you see this</div>
  );

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      console.log({ auth, user });
      if (user) {
        console.log("user logged in");
        setUser(user);
      } else {
        console.log("user not logged in");
      }
    });
  }, []);

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setInputUI((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    //need to do form validation
    if (!auth.currentUser) {
      console.log("user not logged-in, currentUser=null");
      return;
    }

    updateDoc(doc(db, "users", auth.currentUser.uid), {
      ...inputUI,
      phno: auth.currentUser.phoneNumber,
      timeCreated: new Date(),
    }).catch(alert);
    updateProfile(auth.currentUser, {
      displayName: inputUI.name,
    }).catch(alert);
    navigate("/order", { replace: true });
  };

  return (
    <div className="my-auto border-purple-900 rounded-xl border-2 px-5">
      <Form
        inputUI={inputUI}
        handleSubmit={handleSubmit}
        handleInputChange={handleInputChange}
      />
    </div>
  );
}

function Label(props: any) {
  return (
    <label className="text-left" htmlFor={`ip-${props.for}`}>
      {props.children} {" : "}
    </label>
  );
}
function Input(props: any) {
  return (
    <input
      className=""
      type={props.type}
      id={`ip-${props.name}`}
      name={props.name}
      onChange={props.onChange}
      value={props.value}
    />
  );
}

function Field(props: any) {
  return <div className="flex flex-col my-5">{props.children}</div>;
}

function Form(props: any) {
  return (
    <div className="center-flex">
      <form className="text-center" onSubmit={props.handleSubmit}>
        <div className="">
          <Field>
            <Label for="name">Name</Label>
            <Input
              type="text"
              name="name"
              value={props.inputUI ? props.inputUI.name : ""}
              onChange={props.handleInputChange}
            />
          </Field>
          <Field>
            <Label for="fname">Father's name</Label>
            <Input
              type="text"
              name="fname"
              value={props.inputUI ? props.inputUI.fname : ""}
              onChange={props.handleInputChange}
            />
          </Field>
          <Field>
            <Label for="village">Village</Label>
            <Input
              type="text"
              name="village"
              value={props.inputUI ? props.inputUI.village : ""}
              onChange={props.handleInputChange}
            />
          </Field>
        </div>
        <button
          className="ml-4 py-1 px-5 bg-[#705D56] text-[#eed87f]
          font-bold text-lg rounded-lg mb-5"
          type="submit"
        >
          Submit
        </button>
      </form>
    </div>
  );
}
