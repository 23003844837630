import { onAuthStateChanged } from "firebase/auth";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";

function NavLink(props: any) {
  const navigate = useNavigate();
  return (
    <button
      className="px-3 py-1 text-base bg-skin-green rounded"
      onClick={() => navigate(props.target, { replace: true })}
    >
      {props.children}
    </button>
  );
}
function NavButton(props: any) {
  return (
    <button
      className="px-3 py-1 text-base bg-skin-green rounded"
      onClick={props.callback}
    >
      {props.children}
    </button>
  );
}

export default function Header() {
  const navigate = useNavigate();
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {});
  }, []);

  return (
    <header
      className="w-full p-2 text-center flex flex-wrap justify-around items-center 
  text-3xl  text-skin-gold bg-skin-purple sticky top-0	"
    >
      <Link to="/">
        <div className="pl-2 text-center font-bold">
          <img className="h-10" src="/title.png" />
        </div>
      </Link>
      <div className="flex flex-wrap gap-2 justify-center items-center mr-1">
        <NavLink target="/order">Buy</NavLink>
        {auth.currentUser ? (
          <>
            <NavLink target="/user-info">User info</NavLink>
            <NavLink target="/myorder">My orders</NavLink>
            <NavLink target="/mycredit">My credit</NavLink>
            <NavButton
              callback={() => {
                localStorage.setItem("signed-in", "false");
                auth.signOut();
                navigate("sign-in", { replace: true });
              }}
            >
              Sign out
            </NavButton>
          </>
        ) : (
          <NavLink target="/sign-in">Sign in</NavLink>
        )}
      </div>
      {auth.currentUser ? (
        <div className="text-base ml-2">{auth.currentUser.displayName}</div>
      ) : (
        <></>
      )}
    </header>
  );
}
