import "firebaseui/dist/firebaseui.css";
import { useEffect, useState } from "react";
import {
  ConfirmationResult,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { auth, db } from "../firebase";
import { useNavigate } from "react-router-dom";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";

export default function Authentication() {
  const navigate = useNavigate();
  let [recaptchaVerifier, setRecaptchaVerifier] = useState<RecaptchaVerifier>();
  let [inputs, setInputs] = useState<{ [x: string]: string }>();
  let [confirmationResult, setConfirmationResult] =
    useState<ConfirmationResult>();

  useEffect(() => {
    setRecaptchaVerifier(
      new RecaptchaVerifier(
        "recaptchaId",
        {
          size: "invisible",
          callback: (response: any) => {
            console.log("recapcha solved!");
            console.log(response);
          },
        },
        auth
      )
    );
  }, []);

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setInputs((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleSendPhno = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    console.log({ inputs }); //todo: do checks on phone no.
    if (!(inputs && inputs.phno)) {
      console.log("invalid phno");
      return;
    }
    if (!recaptchaVerifier) {
      console.log("recaptch not defined");
      return;
    }
    signInWithPhoneNumber(auth, "+91" + inputs.phno, recaptchaVerifier)
      .then((confirmationResult_tmp) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        console.log("sms sent, confirmation result:");
        console.log(confirmationResult_tmp);
        setConfirmationResult(confirmationResult_tmp);
      })
      .catch((error) => {
        // Error; SMS not sent
        console.log(error);
        alert("invalid phone number");
      });
  };
  const handleFormSubmit = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    console.log({ inputs });
    // window.location.assign("/user-info");
    if (!(inputs && inputs.otp)) {
      console.log("invalid otp");
      return;
    }
    if (!confirmationResult) {
      console.log("invalid confirmationResult sms was not sent");
      return;
    }
    confirmationResult
      .confirm(inputs.otp)
      .then((result) => {
        localStorage.setItem("signed-in", "true");
        getDoc(doc(db, "users", result.user.uid))
          .then((documentSnapshot) => {
            if (documentSnapshot.exists()) {
              navigate("/order", { replace: true });
            } else {
              setDoc(doc(db, "users", result.user.uid), {
                phno: result.user.phoneNumber,
                timeCreated: new Date(),
              })
                .then((docRef: any) => {
                  navigate("/user-info", { replace: true });
                })
                .catch(console.error);
            }
          })
          .catch(console.error);
      })
      .catch(console.error);
  };

  return (
    <div className="my-auto border-purple-900 rounded-xl border-2 px-5 pt-3">
      <div id="recaptchaId"></div>
      <form className="flex flex-col gap-3">
        <div className="flex flex-col">
          <label htmlFor="phno">Phone No.</label>

          <input
            className="w-52"
            type="number"
            id="phno"
            name="phno"
            onChange={handleInputChange}
          />

          <button
            className="py-1 px-5 bg-[#705D56] text-[#eed87f]
          font-bold text-lg rounded-lg mt-2 mb-5"
            type="submit"
            id="send-otp-button"
            onClick={handleSendPhno}
          >
            Send Otp
          </button>
        </div>
        {confirmationResult ? (
          <div className="flex flex-col">
            <label htmlFor="otp">Enter OTP</label>

            <input
              className="w-52"
              type="number"
              id="otp"
              name="otp"
              onChange={handleInputChange}
            />
            <button
              className="mt-2 py-1 px-5 bg-[#705D56] text-[#eed87f]
          font-bold text-lg rounded-lg mb-5"
              type="submit"
              id="sign-in-button"
              onClick={handleFormSubmit}
            >
              Sign In
            </button>
          </div>
        ) : (
          <></>
        )}
      </form>
    </div>
  );
}
