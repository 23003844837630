import { useEffect, useState } from "react";
import { getTotalCreditsPending, getTotalCreditsRcvd } from "../utils";

export interface CreditPending {
  amount: number;
}
export interface CreditRcvd {
  amount: number;
  // time: Date;
}

export default function MyCredit() {
  // let [creditsPending, setCreditsPending] = useState<CreditPending[]>([]);
  // let [creditsRcvd, setCreditsRcvd] = useState<CreditRcvd[]>([]);
  // let [isPending, setIsPending] = useState(true);
  let [totalRcvd, setTotalRcvd] = useState(0);
  let [totalPending, setTotalPending] = useState(0);

  useEffect(() => {
    async function fetchData() {
      setTotalPending(await getTotalCreditsPending());
      setTotalRcvd(await getTotalCreditsRcvd());
    }
    fetchData();
  }, []);

  return (
    <div>
      <div>Credits Pending for next month: {totalPending} Rs</div>
      <div>Total Credits recieved: {totalRcvd} Rs</div>
    </div>
  );
}

// order.time.toDate().toLocaleString()
