import { Link } from "react-router-dom";
import { FaPhoneAlt, FaBookOpen, FaShoppingCart } from "react-icons/fa";
import { GiReceiveMoney } from "react-icons/gi";
import { IconContext } from "react-icons";

function Card(props: any) {
  return (
    <div className="bg-green-600 p-5 center-flex flex-col rounded-md max-w-lg shadow-xl">
      <img
        className="aspect-[1.7] mb-2 rounded-lg object-contain w-11/12"
        src={props.src}
      />
      <div className="flex items-center gap-4">
        <Icon>{props.icon}</Icon>
        <div className="text-xl text-green-50 font-bold">{props.text}</div>
      </div>
    </div>
  );
}
function Icon(props: any) {
  return (
    <IconContext.Provider
      value={{
        color: "white",
        size: "2rem",
        className: "global-class-name",
      }}
    >
      <div>{props.children}</div>
    </IconContext.Provider>
  );
}

export default function Home() {
  return (
    <div className="center-flex mb-auto">
      <div className="grid md:grid-cols-2 grid-cols-1 m-5 gap-5 max-w-4xl">
        <a href="tel:+919999101598">
          <Card
            src="/img/home/call_doctor.jpg"
            text="Call Doctor"
            icon={<FaPhoneAlt />}
          />
        </a>
        <Card
          src="/img/home/plant_pathology.jpg"
          text="Plant Pathology"
          icon={<FaBookOpen />}
        />
        <Link to="/order">
          <Card
            src="/img/home/order_medicine.webp"
            text="Order Product"
            icon={<FaShoppingCart />}
          />
        </Link>
        <Link to="/mycredit">
          <Card
            src="/img/home/farmer.png"
            text="Golden Kisan Samman Nidhi"
            icon={<GiReceiveMoney />}
          />
        </Link>
      </div>
    </div>
  );
}
