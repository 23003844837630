// import './App.css';
import Home from "./components/Home";
import Authentication from "./components/Authentication";
import { Routes, Route } from "react-router-dom";
import UserInfo from "./components/UserInfo";
import { onAuthStateChanged } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { auth } from "./firebase";
import OrderProduct from "./components/OrderProduct";
import Header from "./components/Header";
import Footer from "./components/Footer";
import MyOrder from "./components/MyOrder";
import PrivateRoute from "./PrivateRoute";
import MyCredit from "./components/MyCredit";

export const UserContext = React.createContext(auth.currentUser);

function App() {
  let [currentUser, setCurrentUser] = useState(auth.currentUser);
  let [installPrompt, setInstallPrompt] = useState<any>(null);
  useEffect(() => {
    onAuthStateChanged(auth, () => {
      setCurrentUser(auth.currentUser);
    });
  }, []);

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (e) => {
      console.log("event fired");
      e.preventDefault();
      setInstallPrompt(e);
    });
  }, []);

  return (
    <div className="App flex flex-col items-center min-h-screen bg-skin-pink">
      <UserContext.Provider value={currentUser}>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="sign-in" element={<Authentication />} />
          <Route path="order" element={<OrderProduct />} />
          <Route element={<PrivateRoute />}>
            <Route path="user-info" element={<UserInfo />} />
            <Route path="myorder" element={<MyOrder />} />
            <Route path="mycredit" element={<MyCredit />} />
          </Route>
        </Routes>

        <Footer />
      </UserContext.Provider>
    </div>
  );
}

export default App;
