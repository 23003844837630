import { useNavigate } from "react-router-dom";
import { placeOrder } from "../../utils";
import { Cart } from "../OrderProduct";

interface Props {
  cart: Cart;
  setIsCheckout(bool: boolean): void;
}

export default function Checkout(props: Props) {
  const navigate = useNavigate();
  let tables = [];
  let total = 0;
  for (let key in props.cart) {
    total += props.cart[key].price * props.cart[key].quantity;
    tables.push(
      <table className="border-2">
        <tr>
          <th>Name</th>
          <td>{props.cart[key].name}</td>
        </tr>
        <tr>
          <th>Quantity</th>
          <td>{props.cart[key].quantity}</td>
        </tr>
        <tr>
          <th>Price</th>
          <td>{props.cart[key].price}</td>
        </tr>
        <tr>
          <th>Total Price</th>
          <td>{props.cart[key].price * props.cart[key].quantity}</td>
        </tr>
      </table>
    );
  }
  return (
    <div className="flex mx-3 mb-auto flex-col gap-4">
      {/* <pre id="json">{JSON.stringify(props.cart, undefined, 2)}</pre> */}
      <div className="mt-5">
        Total Amount Payable: <span className="font-bold">{total} Rupees</span>
      </div>
      {tables}

      <button
        className="px-3 py-1 mx-1 text-base bg-skin-green rounded text-skin-gold"
        onClick={() => props.setIsCheckout(false)}
      >
        Go back and review
      </button>
      <button
        className="px-3 py-1 mx-1 text-base bg-skin-green rounded text-skin-gold"
        onClick={() => placeOrder(props.cart, navigate)}
      >
        Place Order
      </button>
    </div>
  );
}
