import { Timestamp } from "firebase/firestore";
import { useEffect, useState } from "react";
import { getOrders } from "../utils";

export interface Order {
  name: string;
  quantity: number;
  price: number;
  time: Timestamp;
}

export default function MyOrder() {
  let [orders, setOrders] = useState<Order[]>([]);
  let [isCompleted, setIsCompeted] = useState(false);

  useEffect(() => {
    getOrders(isCompleted).then(setOrders);
  }, [isCompleted]);

  let total = 0;

  return (
    <div className="mt-3 mb-auto center-flex flex-col">
      <button
        className="ml-4 py-1 px-5 bg-[#705D56] text-[#eed87f]
          font-bold text-lg rounded-lg mb-5"
        onClick={() => setIsCompeted(!isCompleted)}
      >
        {isCompleted ? "Show Pending Orders" : "Show Completed Orders"}
      </button>
      {orders.map((order) => {
        total += order.price * order.quantity;
        return (
          <table className="mb-5 border-2 mx-3">
            <tr>
              <th>Name</th>
              <td>{order.name}</td>
            </tr>
            <tr>
              <th>Quantity</th>
              <td>{order.quantity}</td>
            </tr>
            <tr>
              <th>Price</th>
              <td>{order.price}</td>
            </tr>
            <tr>
              <th>Time</th>
              <td>{order.time.toDate().toLocaleString()}</td>
            </tr>
            <tr>
              <th>Total Price</th>
              <td>{order.price * order.quantity}</td>
            </tr>
          </table>
        );
      })}
      {!isCompleted ? (
        <div>
          Total Amount Payable:{" "}
          <span className="font-bold">{total} Rupees</span>
        </div>
      ) : (
        <></>
      )}

      {/* <pre id="json">{JSON.stringify(orders, undefined, 2)}</pre> */}
    </div>
  );
}

// <table>
//   <tr>
//     <th>Name</th>
//     <th>Quantity</th>
//     <th>Price</th>
//     <th>Time</th>
//     <th>Total Price</th>
//   </tr>
//   {orders.map((order) => {
//     return (
//       <tr>
//         <td>{order.name}</td>
//         <td>{order.quantity}</td>
//         <td>{order.price}</td>
//         <td>{order.time.toDate().toLocaleString()}</td>
//         <td>{order.price * order.quantity}</td>
//       </tr>
//     );
//   })}
//   <tr>
//     <th colSpan={4}>Total Payable Amount</th>
//     <th>{total}</th>
//   </tr>
// </table>
