import { useContext } from "react";
import { Outlet } from "react-router-dom";
import { UserContext } from "./App";
import Authentication from "./components/Authentication";

const PrivateRoute = () => {
  let userContext = useContext(UserContext);
  let aa = localStorage.getItem("signed-in");
  let localLoggedin = aa && aa == "true";
  return localLoggedin || userContext ? <Outlet /> : <Authentication />;
};

export default PrivateRoute;
