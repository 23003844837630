import { useEffect, useState } from "react";
import { getProducts, ProductData } from "../utils";
import Checkout from "./subComponents/Checkout";
import { FaPlus, FaMinus } from "react-icons/fa";
import { IconContext } from "react-icons";

interface CartUI {
  [x: string]: { quantity: number };
}
export interface Cart {
  [x: string]: { name: string; quantity: number; price: number };
}

export default function OrderProduct() {
  let [productList, setProductList] = useState<ProductData[]>([]);
  let [cart, setCart] = useState<Cart>();
  let [cartUI, setCartUI] = useState<CartUI>();
  let [isCheckout, setIsCheckout] = useState(false);

  useEffect(() => {
    console.log(cart);
  }, [cart]);

  useEffect(() => {
    getProducts().then(setProductList);
  }, []);

  useEffect(() => {
    if (productList.length == 0) {
      return;
    }
    let localCart = localStorage.getItem("cart");
    let tmptmpCart: Cart = {};
    if (localCart) {
      console.log("using local cart");
      tmptmpCart = JSON.parse(localCart);
    }
    let tmpCart: Cart = {};
    let tmpCartUI: CartUI = {};
    productList.forEach((product) => {
      if (tmptmpCart[product.key]) {
        //is it necessary for this check?
        tmpCart[product.key] = tmptmpCart[product.key];
        tmpCartUI[product.key] = { quantity: tmptmpCart[product.key].quantity };
      } else {
        tmpCartUI[product.key] = {
          quantity: 0,
        };
      }
    });
    setCart(tmpCart);
    setCartUI(tmpCartUI);
  }, [productList]);

  const handleQuantityChange = (
    key: string,
    amount: number,
    name: string,
    price: number,
    quantity: number
  ) => {
    if (!cartUI) {
      console.log("handleQuantityChange, cartUI is undefined");
      return;
    }
    if (cartUI[key].quantity + amount < 0) {
      return;
    }
    // setInputUI((prevState) => ({ ...prevState, [name]: value }));
    handleAddToCart(key, name, price, quantity + amount);
    setCartUI({
      ...cartUI,
      [key]: {
        quantity: cartUI[key].quantity + amount,
      },
    });
  };

  useEffect(() => {
    if (cart) {
      localStorage.setItem("cart", JSON.stringify(cart));
    }
  }, [cart]);

  const handleAddToCart = (
    key: string,
    name: string,
    price: number,
    amount: number
  ) => {
    if (amount <= 0) {
      return;
    }
    setCart({
      ...cart,
      [key]: {
        name: name,
        quantity: amount,
        price: price,
      },
    });
  };

  if (productList.length == 0 || !cartUI) {
    return <div>Loading...</div>;
  }
  if (isCheckout) {
    return <Checkout cart={cart ? cart : {}} setIsCheckout={setIsCheckout} />;
  }
  return (
    <div className="w-screen center-flex flex-col">
      <div className="w-11/12 max-w-[550px]">
        {productList.map((product) => {
          return (
            <ProductCard
              key={product.key}
              pkey={product.key}
              imgUrl={product.img}
              name={product.name}
              price={product.price}
              package={product.package}
              quantity={
                cartUI && cartUI[product.key]
                  ? cartUI[product.key].quantity
                  : 999
              }
              handleQuantityChange={handleQuantityChange}
              handleAddToCart={handleAddToCart}
            />
          );
        })}
      </div>
      <button
        className="px-3 py-1 mx-1 text-base bg-skin-green rounded text-skin-gold"
        onClick={() => setIsCheckout(true)}
      >
        Review and Order
      </button>
    </div>
  );
}

function Icon(props: any) {
  return (
    <IconContext.Provider
      value={{
        color: "#705d56",
        size: "20px", //1.4rem
        className: "global-class-name",
      }}
    >
      <div>{props.children}</div>
    </IconContext.Provider>
  );
}

function ProductCard(props: any) {
  return (
    <div className="flex m-3 bg-slate-200 rounded-lg overflow-hidden shadow-lg">
      <img
        className="w-2/5 aspect-[0.8] object-contain bg-skin-white shadow-xl"
        src={props.imgUrl}
      />
      <div className="w-3/5 center-flex flex-col">
        <div className=" text-3xl mb-3 mt-5">{props.name} </div>
        <div className=" text-md mb-1 mt-1">
          {"Packaging: " + props.package}{" "}
        </div>
        <div className="mb-2 text-xl">Price : {props.price} Rs.</div>
        <fieldset className="max-w-[90%] min-w-[70%] border-teal-400 border-2 rounded-2xl p-2 mb-7">
          <legend className="mx-auto">
            <div className="px-[5px] text-center">Select Quantity</div>
          </legend>
          <div className="flex items-center justify-around">
            <button
              className="bg-blue-400 p-2 rounded-full"
              onClick={() =>
                props.handleQuantityChange(
                  props.pkey,
                  -1,
                  props.name,
                  props.price,
                  props.quantity
                )
              }
            >
              <Icon>
                <FaMinus />
              </Icon>
            </button>
            <div className="text-center text-xl">{props.quantity}</div>
            <button
              className="bg-blue-400 p-2 rounded-full"
              onClick={() =>
                props.handleQuantityChange(
                  props.pkey,
                  +1,
                  props.name,
                  props.price,
                  props.quantity
                )
              }
            >
              <Icon>
                <FaPlus />
              </Icon>
            </button>
          </div>
        </fieldset>
        {/* <button
          className="h-10 px-6 bg-[#705D56] text-[#eed87f]
          font-bold text-lg rounded-lg mb-5"
          onClick={() =>
            props.handleAddToCart(
              props.pkey,
              props.name,
              props.price,
              props.quantity
            )
          }
        >
          Add to Cart
        </button> */}
      </div>
    </div>
  );
}
