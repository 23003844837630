import { auth, db, storageRef } from "./firebase";
import { getDownloadURL, ref } from "firebase/storage";
import {
  collection,
  getDocs,
  writeBatch,
  doc,
  Timestamp,
  query,
  where,
  orderBy,
} from "firebase/firestore";
import { Cart } from "./components/OrderProduct";
import { Order } from "./components/MyOrder";
import { CreditPending, CreditRcvd } from "./components/MyCredit";

export interface ProductData {
  img: string;
  name: string;
  price: number;
  key: string;
  package: string;
}

export function getProducts(): Promise<ProductData[]> {
  let productList: ProductData[] = [];
  return getDocs(collection(db, "products"))
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        productList.push({
          img: data.imgUrl,
          name: data.name,
          price: data.price,
          key: doc.id,
          package: data.package,
        });
      });
      return productList;
    })
    .catch((error) => {
      console.log("firestore query error occured");
      console.log(error);
      return [];
    });
}

export function placeOrder(cart: Cart, navigate: any) {
  if (!auth.currentUser) {
    navigate("/sign-in", { replace: true });
    return;
  }
  const batch = writeBatch(db);

  let dataList = []; //list of data to push to cloud
  for (let item in cart) {
    dataList.push({
      ...cart[item],
      time: Timestamp.fromDate(new Date()),
      orderPending: true,
    });
  }
  dataList.forEach((data) => {
    let docRef = doc(
      collection(db, "users", auth.currentUser!.uid, "transactions")
    );
    batch.set(docRef, data);
  });
  let docRef = doc(db, "users", auth.currentUser!.uid);
  batch.update(docRef, { orderPending: true });
  batch
    .commit()
    .then(() => {
      navigate("/myorder", { replace: true });
    })
    .catch(alert);
  localStorage.removeItem("cart");
}

export function getOrders(isCompleted: boolean): Promise<Order[]> {
  //input for pending or completed orders
  if (!auth.currentUser) {
    return new Promise(() => []);
  }
  let orderList: any = [];
  let docRef = collection(db, "users", auth.currentUser?.uid, "transactions");
  let q = query(
    docRef,
    where("orderPending", "==", !isCompleted) //just sort it locally if needed
    //orderBy("time", "desc") // build a composite index in firebase console
  ); //can also use ,limit(10) to only get 10 results
  return getDocs(q)
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        orderList.push(data);
      });
      return orderList;
    })
    .catch((error) => {
      console.log("firestore getOrders error occured");
      console.log(error);
      return [];
    });
}

async function getCreditsPending(): Promise<CreditPending[]> {
  let credits: CreditPending[] = [];
  if (!auth.currentUser || !auth.currentUser.uid) {
    return [];
  }
  let docRef = collection(db, "users", auth.currentUser.uid, "credits");
  let q = query(docRef, where("numTransfer", "<", 3));
  try {
    let querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      let data = doc.data();
      credits.push({ amount: data.amount });
    });
  } catch (error) {
    alert(error);
    return [];
  }
  return credits;
}
export async function getTotalCreditsPending(): Promise<number> {
  let ans = 0;
  let creditList = await getCreditsPending();
  creditList.forEach((credit) => {
    ans += credit.amount;
  });
  return ans;
}
async function getCreditsRcvd(): Promise<CreditRcvd[]> {
  let credits: CreditRcvd[] = [];
  if (!auth.currentUser || !auth.currentUser.uid) {
    return [];
  }
  let docRef = collection(db, "users", auth.currentUser.uid, "creditsRecieved");
  let q = query(docRef);
  try {
    let querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      let data = doc.data();
      credits.push({ amount: data.amount }); //, time: data.time.toDate()
    });
  } catch (error) {
    alert(error);
    return [];
  }
  return credits;
}
export async function getTotalCreditsRcvd(): Promise<number> {
  let ans = 0;
  let creditList = await getCreditsRcvd();
  creditList.forEach((credit) => {
    ans += credit.amount;
  });
  return ans;
}
