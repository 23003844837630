export default function Footer() {
  return (
    // <footer
    //   className="h-14 text-center flex justify-center items-center
    // bg-green-900 text-3xl font-bold text-green-100 mt-auto"
    // >
    //   by Bandhuja Agrofusion Pvt. Ltd.
    // </footer>
    <div
      className="text-center flex justify-center items-center
      text-base text-skin-purple pt-4 mb-1"
    >
      © by B.A.F.P. Ltd.
    </div>
  );
}
